
<!-- template for the modal component -->
<template>
  <transition name="modal">
    <div class="cali__modal-mask" role="dialog">
      <div class="cali__modal-dialog" role="document">
        <div class="cali__modal-content" :class="('size-' + modalSize)" :id="uniqueId">

          <div class="cali__modal-header">
            <slot name="header">
              <h4 class="cali__modal-title">{{ title || 'Default title' }}</h4>
            </slot>
            <button type="button" class="cali_modal-btn-close" @click="$emit('close')">
              <img class="cali__modal-close-modal" v-if="closeModalImage.src" :src="closeModalImage.src"
                :alt="closeModalImage.alt" />

              <img :src="`https://tba.mints.cloud/public-assets/${icon || 'close'}`" v-else alt="">
            </button>
          </div>

          <div class="cali__modal-body">
            <slot name="body">
              Default body
            </slot>
          </div>

          <div class="cali__modal-footer d-flex">
            <slot name="footer">
              <button type="button" class="cali__modal-btn-primary" @click="$emit('close')">OK</button>
              <button type="button" class="cali__modal-btn-secondary" @click="$emit('close')"><i class="fal fa-times"></i>
                Close</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeModalImage: {
      type: Object,
      default: () => ({
        src: null,
        alt: null
      })
    },
    title: String,
    icon: {
      type: String,
      default: 'close'
    },
    modalSize: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    uniqueId() {
      return 'modal__' + this?.$?.type?.__scopeId + '__' + this?.$?.uid
    }
  },
  methods: {
    manageClick(e) {
      if (!document.getElementById(this.uniqueId).contains(e.target)) {
        this.$emit('close');
      }
    }
  },
  created() {
    document.body.style.overflow = 'hidden';

  },
  beforeUnmount() {
    document.body.style.overflow = '';
  }
}
</script>

<style scoped lang="scss">
@import "styles.css";

.preview-pdf {
  background-color: #E9ECEF !important;

  .cali__modal-content {
    padding: 0 !important;
    border: 0 !important;
    margin-block: 30px;
    border-radius: 0 !important;

    .cali__modal-header {
      background-color: white !important;
      background: white !important;
      height: 80px;
      width: 100%;
      max-width: 92%;
      margin: auto;
      padding-bottom: 0 !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #DCDCDC !important;

      .cali_modal-btn-close {
        position: absolute;
        top: -50px;
        right: -30px;

        img {
          filter: invert(.5) !important;
        }
      }
    }
    .cali__modal-footer{
      border-radius: 0 !important;
      background-color: white;
    }

    .cali__modal-body {
      padding: 0 65px !important;
    }
  }

}
</style>
